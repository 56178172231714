import React, { useContext, useEffect, useState } from 'react';
import { useFirebase } from 'components/firebase/Firebase';


const AuthUserContext = React.createContext(null); //create a context to give access to the authenticated user

export default AuthUserContext;



export const AuthenticationProvider = (props) => {
  const [authUser, setAuthUser] = useState<null>(null)

  const firebase = useFirebase()

  useEffect(
    () => firebase.auth.onAuthStateChanged(setAuthUser), //listen for changes to the authenticated user
    [firebase]
  )

  return ( //set the authenticated user as the value that the AuthUserContext Provider pass to its Consumers
    <AuthUserContext.Provider value={authUser}>
      {props.children}
    </AuthUserContext.Provider>
  );
}



export const useAuthentication = () => useContext(AuthUserContext)