// note that you must have this code in gatsby-browser.js AND gatsby-ssr.js
import React from 'react'
import { Helmet } from "react-helmet"
import './src/index.scss'

import 'bootstrap/dist/css/bootstrap.min.css';
import "./src/bootstrapOverrides.scss"
import "./src/index.scss"


// route components
import NavbarNew from "./src/components/navbar/NavbarNew";

import "./src/utils/productionConsole/productionConsole"

//firebase stuff
import { FirebaseInstance, FirebaseContext } from './src/components/firebase/Firebase';
import { AuthenticationProvider } from './src/components/firebase/AuthUserContext';


//https://www.apollographql.com/docs/react/api/react/hooks/#the-apolloprovider-component
import { ApolloProvider } from '@apollo/client'
import client from "./src/utils/graphqlSetup"

export const wrapPageElement = ({element}) => {
  return (
    <FirebaseContext.Provider value={FirebaseInstance}>
      <ApolloProvider client={client}>
        <AuthenticationProvider>
          <Helmet>
            <meta charset="utf-8" />
            <link rel="shortcut icon" href="./favicon.ico" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1, shrink-to-fit=no"
            />
            <meta name="theme-color" content="#000000" />

            <title>LobbyView | Advancing data science research in interest group politics</title>
            <meta name="description" content="LobbyView allows scholars, journalists, and citizens to learn more about lobbying in the U.S."/>
            <meta name="keywords" content="lobby, view, lobbyview, lobbying, visualization, reports, data, politician, bill, congress, politics"/>
            <meta property="og:url"           content="https://www.lobbyview.org/" />
            <meta property="og:type"          content="website" />
            <meta property="og:title"         content="LobbyView | Advancing data science research in interest group politics" />
            <meta property="og:description"   content="Advancing data science research in interest group politics" />
            <meta property="og:image"         content="/lobbyview.jpg" />
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:title" content="LobbyView | Advancing data science research in interest group politics"/>
            <meta name="twitter:description" content="LobbyView allows scholars, journalists, and citizens to learn more about lobbying in the U.S."/>
            <meta name="twitter:image" content="/lobbyview.jpg"/>
          </Helmet>

          <NavbarNew/>

          {element}
        </AuthenticationProvider>
      </ApolloProvider>
    </FirebaseContext.Provider>
  )
}
