import React from 'react'
// import { ApolloClient } from 'apollo-client'
// import { ApolloConsumer } from 'react-apollo'
// import { ApolloConsumer } from '@apollo/react-common';
import { createHttpLink } from 'apollo-link-http'
// import { setContext } from 'apollo-link-context'
// import { setContext } from "@apollo/client/link/context"
// import { InMemoryCache } from 'apollo-cache-inmemory'
import fetch from 'isomorphic-fetch'
import PROTOCOL from 'utils/protocol/protocol'
import { FirebaseInstance } from 'components/firebase/Firebase';

import {
  ApolloClient,
  ApolloConsumer,
  // ApolloLink,
  // concat,
  // HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const httpLink = createHttpLink({
  fetch, //https://stackoverflow.com/questions/64362315/gatsby-webpackerror-invariant-violation-error-with-apolloclient
  uri: PROTOCOL + process.env.REACT_APP_GRAPHQL_URL
})

//https://www.apollographql.com/docs/react/networking/advanced-http-networking/#customizing-request-logic
// const authMiddleware = new ApolloLink((operation, forward) => {
//   // add the authorization to the headers
//   operation.setContext(
//     async ({ headers = {} }) => {
//       // console.log("REQUEST", _.query.loc.source.body)
//       // console.log("VARIABLES", _.variables)
//       const firebaseIdToken = await FirebaseInstance.auth?.currentUser?.getIdToken(true);
//       const firebaseUserUid = FirebaseInstance.auth.currentUser?.uid;
//       // console.log("firebaseIdToken",firebaseIdToken)
      
//       return {
//         headers: {
//           ...headers,
//           'firebase-id-token': firebaseIdToken,
//           'firebase-user-uid': firebaseUserUid,
//         }
//       }
//     }
//   );

//   return forward(operation);
// })
const authLink = setContext(async (_, { headers }) => { //customize request headers https://www.apollographql.com/docs/react/networking/authentication/
  console.log("REQUEST", _.query.loc.source.body)
  console.log("VARIABLES", _.variables)
  const firebaseIdToken = await FirebaseInstance.auth?.currentUser?.getIdToken(true);
  const firebaseUserUid = FirebaseInstance.auth.currentUser?.uid;
  console.log("firebaseIdToken",firebaseIdToken)
  return {
    headers: {
      ...headers,
      'firebase-id-token': firebaseIdToken,
      'firebase-user-uid': firebaseUserUid,
    }
  }
});

//I (Harry) am disabling the cache because it causes an out of memory leak when it tries to normalize the reports data
//when you search by keywords and you have cache enabled, you will cause an out of memory crash
//how the cache normalizes data: https://www.apollographql.com/docs/react/caching/cache-configuration/
const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
})

export default client


export const withApolloClient = Component => props => (
  <ApolloConsumer>
    {client => <Component {...props} client={client}/>}
  </ApolloConsumer>
);
